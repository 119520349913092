<template>
  <div class="">
    <b-card>
      <b-card-body>
        <v-date-picker
          style="width: 100%; max-width: 25rem"
          v-model="range"
          is-range
          :masks="masks"
        />
      </b-card-body>
      <b>※現在は使用できません。</b>
    </b-card>
    <farms-camera-picture-list :cameraKey="this.cameraKey" />
  </div>
</template>

<script>
import FarmsCameraPictureList from "../components/FarmsCameraPictureList";

export default {
  components: {
    FarmsCameraPictureList
  },
  data() {
    return {
      range: {
        start: new Date(),
        end: new Date(),
      },
      masks: { title: "YYYY年 MMM" },
      cameraKey: this.$route.query.camera_key,
    };
  },
};
</script>
