<template>
  <div>
    <modal-image-display ref="modalImageDisplay" />
    <div v-if="$apollo.loading">Loading...</div>
    <div v-else>
      <b-card-group deck>
        <div class="" style="padding-bottom: 1.2rem" v-for="item in this.farmPictures.edges" :key="item.node.id">
          <b-card no-body class="card h-100" style="width: 16rem; margin-right: 0.6rem; margin-left: 0.6rem">
            <b-img :src="`/${item.node.path}`" alt="" class="card-img-top" @click=showImageDisplay :data-title="item.node.fileName"></b-img>
            <b-card-body style="padding: 0.6rem">
              <h5 class="card-title">{{item.node.cameraName}}</h5>
            </b-card-body>
            <b-card-footer>
              <p class="card-text text-center">{{item.node.uploadDatetime}}</p>
            </b-card-footer>
          </b-card>
        </div>
      </b-card-group>
    </div>
  </div>
</template>

<script>
import { FARM_PICTURES_QUERY } from "../../components/camera-picture-query";
import { nullCheckConvert } from "../../components/null-check";
// -- Component --
import ModalImageDisplay from "./ModalImageDisplay";

export default {
  props: {
    cameraKey: null
  },
  components: {
    ModalImageDisplay
  },
  data() {
    return {
    };
  },
  created() {
    this.refetch();
  },
  apollo: {
    farmPictures: {
      query: FARM_PICTURES_QUERY,
      variables () {
        return {
          cameraKey: this.cameraKey,
        }
      },
      result({ data, loading }) {
        console.log(data);
      },
      error(error) {
        console.error("farmPictures", error);
      }
    }
  },
  methods: {
    refetch: function() {
      this.$apollo.queries.farmPictures.refetch();
    },
    showImageDisplay: function(e) {
      let src = e.currentTarget.getAttribute('src');
      let alt = e.currentTarget.getAttribute('alt');
      let title = e.currentTarget.getAttribute('data-title');
      this.$refs.modalImageDisplay.showDialog({
        src: src,
        alt: alt,
        title: title,
      });
    },
  }
};
</script>
