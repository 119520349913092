import gql from "graphql-tag";

export const LATEST_FARM_PICTURES_QUERY = gql`
  query{
    latestFarmPictures{
      edges{
        node{
          id
          farmName
          cameraKey
          cameraName
          path
          fileName
          uploadDatetime
        }
      }
    }
  }
`;

export const FARM_PICTURES_QUERY = gql`
  query($cameraKey:String){
    farmPictures(cameraKey:$cameraKey ){
      edges{
        node{
          id
          farmName
          cameraKey
          cameraName
          path
          fileName
          uploadDatetime
        }
      }
    }
  }
`;

