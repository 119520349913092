<template>
  <b-modal ref="dialog" size="xl" @hidden="hideDialog" hide-footer :title="this.image.title">
    <b-img :src="this.image.src" :alt="this.image.alt" class="w-100"></b-img>
  </b-modal>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      image: {}
    };
  },
  props: {},
  watch: {},
  methods: {
    // --------------------------------
    // modalを開く
    // --------------------------------
    showDialog: function(param) {
      this.image = param;
      this.$refs["dialog"].show();
    },
    hideDialog: function() {
      this.image = {};
      this.$refs["dialog"].hide();
    }
  }
};
</script>
